import { NgModule, APP_INITIALIZER, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AgmCoreModule } from '@agm/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ImageCropperModule } from 'ngx-image-cropper';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from './core/core.module';

// Services/Providers
import { TranslateProvider } from './providers';

// Modal Pages

// Environment
import { environment } from '../environments/environment';

// Components


// Pipes
import { PipesModule } from './pipes/pipes.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';

import { ClientSetting } from './core/client-setting';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function initializeApp(clientSetting: ClientSetting) {
  return () => clientSetting.load();
}
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(environment.config),
    AppRoutingModule,
    HttpClientModule,

    CoreModule,
    ImageCropperModule,
    MatInputModule,
    MatAutocompleteModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PipesModule
  ],
   providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    TranslateProvider,
    ClientSetting,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ClientSetting],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
