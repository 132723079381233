import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterFLNameArray'
})
export class FilterFLNameDataPipe implements PipeTransform {
    // transform(items: Array<any>, filter: { [key: string]: any }): Array<any> {
        transform(items: any, filter?: any): any {
            if (!(filter === null || filter === undefined) && filter) {
            return items.filter(currentItem => {
                return ((currentItem.FirstName + (currentItem.LastName ? null : '' + currentItem.LastName))
                .toLowerCase().indexOf(filter.toLowerCase()) > -1);
            });
        } else {
            return items;
        }
    }
}
