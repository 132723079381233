import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services';

@Injectable()
export class SMSService {
    constructor(private apiService: ApiService) { }

    getAndSendOTP(mobileNumber: string): Observable<string> {
        const route = '/v1/sendOTP?MobileNumber=' + mobileNumber;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getAndSendRecoveryOTP(mobileNumber: string, userID: string): Observable<string> {
        const route = '/v1/sendRecoveryOTP?mobileNumber=' + mobileNumber + '&userID=' + userID;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    sendPasswordSMSToMember(mobileNumber: string, Secret: string): Observable<any> {
        const route = '/v1/sendPasswordSMS?mobileNumber=' + mobileNumber + '&Secret=' + Secret;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }
}

