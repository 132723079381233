import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundPipe',
  pure: false
})

export class RoundPipe implements PipeTransform {
  constructor() {}
  transform(value: number): any {
    if (value) {
      return value.toFixed(2);
    }
  }
}
