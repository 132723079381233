import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api-service';
import { map } from 'rxjs/operators';
import { UtilityService } from './utility-service';
import { UserAuthorities } from '../models/authorities-model';

@Injectable()
export class UserAuthorityService {

    constructor(private apiService: ApiService, private utilityService: UtilityService) { }

    createDefaultAuthorities(auths: UserAuthorities): Observable<any> {
        const route = '/v1/createDefaultAuthorities';
        return this.apiService.post(route, auths)
            .pipe(map(
                data => {
                    if (this.utilityService.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return data;
                }
            ));
    }
}
