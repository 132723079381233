const messages: Array<any> = [
    {
        id: 1,
        title: 'New Offer 25% OFF',
        date: '2017-11-01T00:00:00.000-0300',
        senderId: 2,
        senderName: 'Caroline Broker',
        email: 'caroline@ionbooking.com',
        message: 'Please body! contact me for help you with my new offer, keep in touch for this or other offers.',
        read: false
    },
    {
        id: 2,
        title: 'New Offer 15% OFF!',
        date: '2017-11-01T00:00:00.000-0300',
        senderId: 3,
        senderName: 'Michael Jones',
        email: 'michael@ionbooking.com',
        message: 'Please body! contact me for help you with my new offer, keep in touch for this or other offers.',
        read: true
    },
    {
        id: 3,
        title: 'New Cruises coming!',
        date: '2017-10-20T00:00:00.000-0300',
        senderId: 3,
        senderName: 'Michael Jones',
        email: 'michael@ionbooking.com',
        message: 'Please body! contact me for help you with my new offer, keep in touch for this or other offers.',
        read: true
    },
    {
        id: 4,
        title: 'Your New Trip',
        date: '2017-10-09T00:00:00.000-0300',
        senderId: 5,
        senderName: 'Jessica Stevens',
        email: 'jessica@ionbooking.com',
        message: 'Please body! contact me for help you with my new offer, keep in touch for this or other offers.',
        read: false
    }
];

export default messages;
