import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import * as _ from 'underscore';
import { UserAuthoritiesModel } from '../models/administration/user-authorities.model';

@Injectable()
export class JwtService {

  constructor() { }

  /** Start JWT Token Properties*/
  getToken(): string {
    return window.localStorage['jwtToken'];
  }

  saveToken(token: string) {
    window.localStorage['jwtToken'] = token;
  }

  destroyToken() {
    window.localStorage.removeItem('jwtToken');
  }

  getUserAuthority(): string {
    return  window.localStorage.getItem('userAuthority');
    // return window.localStorage['userAuthority'];
  }



  saveUserAuthority(authority: string) {
    window.localStorage['userAuthority'] = authority;
  }



  destroyUserAuthority() {
    window.localStorage.removeItem('userAuthority');
    window.localStorage.removeItem('selectedCompany');
    window.localStorage.removeItem('slectedfinancialYear');

  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  geAccessTokenValues(): any {
    return this.getDecodedAccessToken(this.getToken());
  }

  getTokenExpirationDate(token: string): Date {
    const decoded: any = jwt_decode(token);

    if (decoded.exp === undefined) { return null; }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if (!token) { token = this.getToken(); }
    if (!token) { return true; }

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) { return false; }
    return !(date.valueOf() > new Date().valueOf());
  }

  getModuleAuthority(module: string): boolean {
    const allAuthority = JSON.parse(this.getUserAuthority());
    const moduleAuthority = _.filter(allAuthority, function (t) { return t.ModuleName.toLowerCase() === module.toLowerCase(); });
    if (moduleAuthority !== null && moduleAuthority !== undefined && moduleAuthority.length > 0) {
      return true;
    }
    return false;
  }

  getPageAuthority(page: string): UserAuthoritiesModel {
    const allAuthority = JSON.parse(this.getUserAuthority());
    const moduleAuthority = _.filter(allAuthority, function (t) { return t.AuthorityName.toLowerCase() === page.toLowerCase(); });
    if (moduleAuthority !== null && moduleAuthority !== undefined) {
      return _.first(moduleAuthority);
    }
    return null;
  }

  getPageAuthorityByID(page: number): UserAuthoritiesModel {
    const allAuthority = JSON.parse(this.getUserAuthority());
    const moduleAuthority = _.filter(allAuthority, function (t) { return t.AuthorityID === page; });
    if (moduleAuthority !== null && moduleAuthority !== undefined) {
      return _.first(moduleAuthority);
    }
    return null;
  }
  /** End JWT Token Properties*/
}
