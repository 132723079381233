import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { map } from 'rxjs/operators';
import { ApiService } from '../api-service';
import { UserInfoModel } from '../../models/administration/user-info-model';
import { RoleModuleModel } from '../../models/administration/role-module.model';
import { UserAuthoritiesModel } from '../../models/administration/user-authorities.model';

@Injectable()
export class UserInfoService {
    constructor(private apiService: ApiService) { }
    private userInfoBO = new BehaviorSubject<UserInfoModel>(null);

    setUserInfoData(userData: UserInfoModel) {
        this.userInfoBO.next(userData);
    }

    getUserInfoData(): Observable<UserInfoModel> {
        return this.userInfoBO.asObservable();
    }

    createUpdateUserInfo(userInfo: UserInfoModel): Observable<any> {
        const route = '/v1/createUserInfo';
        return this.apiService.post(route, userInfo)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return data;
                }
            ));
    }

    updateUserAuthorities(userInfo: UserInfoModel): Observable<any> {
        const route = '/v1/updateUserAuthorities';
        return this.apiService.post(route, userInfo)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return data;
                }
            ));
    }

    getUsersList(): Observable<UserInfoModel[]> {
        const route = '/v1/getAllUserInfo';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    deleteUserInfo(id: number, email: string): Observable<any> {
        const route = '/v1/deleteUserInfo?id=' + id + '&Email=' + email;
        return this.apiService.post(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    uploadProfileImages(userInfo: UserInfoModel): Observable<any[]> {
        const route = '/v1/UploadUserProfileImage';
        return this.apiService.post(route, userInfo)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    updatePassword(userInfo: UserInfoModel): Observable<any> {
        const route = '/v1/updateUserpassword';
        return this.apiService.post(route, userInfo)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return data;
                }
            ));
    }

    blockUserInfo(ID: number, email: string): Observable<any> {
        const route = '/v1/BlockUserInfo?ID=' + ID + '&Email=' + email;
        return this.apiService.post(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return data;
                }
            ));
    }

    unBlockUserInfo(ID: number, email: string): Observable<any> {
        const route = '/v1/UnBlockUserInfo?ID=' + ID + '&Email=' + email;
        return this.apiService.post(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return data;
                }
            ));
    }

    getUserModulesList(secret: string): Observable<RoleModuleModel[]> {
        const route = '/v1/getUserModules?Secret=' + secret;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getUserAuthorities(username: string, showAllAuthorities: boolean): Observable<UserAuthoritiesModel[]> {
        const route = '/v1/getUserAuthorities?username=' + username + '&ShowAllAuthorities=' + showAllAuthorities;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }


    getUserBySecret(secret: string): Observable<UserInfoModel> {
        const route = '/v1/GetUserInfoBySecret?Secret=' + secret;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }
}
