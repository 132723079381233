import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api-service';

import { map } from 'rxjs/operators';
import { LedgerReport, PosItemResponse, TransMainResponse, TrialBalance } from '../../models/accounting/account-model';
import { LedgerBal, MasterList } from '../../models/accounting/account-ledger.model';

@Injectable()
export class LedgerMasterService {
    constructor(private apiService: ApiService) { }
    private trialBalance = new BehaviorSubject<TrialBalance>(null);
    // eslint-disable-next-line @typescript-eslint/member-ordering
    private ledgerData = new BehaviorSubject<LedgerReport>(null);
    // setAccountingGroupData(plottingGroup: AccountingLedgerModel) {
    //     this.accountingGroup.next(plottingGroup);
    // }

    // getAccountingGroupData(): Observable<AccountingLedgerModel> {
    //     return this.accountingGroup.asObservable();
    // }

    setTrialBalanceData(trialBalance: TrialBalance) {
      this.trialBalance.next(trialBalance);
  }
    getTrialBalanceData(): Observable<TrialBalance> {
      return this.trialBalance.asObservable();
}

    setLedgerData(ledgerData: LedgerReport) {
      this.ledgerData.next(ledgerData);
    }
    getLedgerData(): Observable<LedgerReport> {
      return this.ledgerData.asObservable();
    }


    getTrialBalance(companyId: number, fyearId: number): Observable<TrialBalance[]> {
      const route = '/v1/client/reports/TrialBalance?companyId=' + companyId + '&fyearId=' + fyearId;
      return this.apiService.get(route)
          .pipe(map(
              data => {
                  if (data.status === undefined || data.status === null || !data.status) {
                      throw new Error(data.error_description);
                  }
                  return (data.data);
              }
          ));
  }

  getLedgerReport(companyId: number, fyearId: number,ledgercode: string): Observable<LedgerReport[]> {
    const route = '/v1/client/reports/LedgerReport?companyId=' + companyId + '&fyearId=' + fyearId + '&ledgercode=' + ledgercode;
    return this.apiService.get(route)
        .pipe(map(
            data => {
                if (data.status === undefined || data.status === null || !data.status) {
                    throw new Error(data.error_description);
                }
                return (data.data);
            }
        ));
}


getLedgerMaster(companyId: number,fyearId: number): Observable<MasterList[]> {
  const route = '/v1/master/masterlist?companyId=' + companyId + '&fyearId=' + fyearId;
  return this.apiService.get(route)
      .pipe(map(
          data => {
              if (data.status === undefined || data.status === null || !data.status) {
                  throw new Error(data.error_description);
              }
              return (data.data);
          }
      ));
}

getLedgerBalance(companyId: number, fyearId: number,groupId: number): Observable<LedgerBal[]> {
  const route = '/v1/client/reports/LedgerBal?companyId=' + companyId + '&fyearId=' + fyearId + '&groupId=' + groupId;
  return this.apiService.get(route)
      .pipe(map(
          data => {
              if (data.status === undefined || data.status === null || !data.status) {
                  throw new Error(data.error_description);
              }
              return (data.data);
          }
      ));
}

getBillDetails(companyId: number, fyearId: number,voucher: string,sno: number,transDate: string): Observable<TransMainResponse> {
  const route = '/v1/transmain/get-bill-detail?companyId=' + companyId + '&fyearId=' + fyearId + '&voucher=' + voucher +
   '&sno=' + sno + '&transDate=' + transDate;
  return this.apiService.get(route)
      .pipe(map(
          data => {
              if (data.status === undefined || data.status === null || !data.status) {
                  throw new Error(data.error_description);
              }
              return (data.data);
          }
      ));
}

getAllItems(companyId: number,fyearId: number): Observable<PosItemResponse[]> {
  const route = '/v1/items/get-all-items?companyId=' + companyId + '&fyearId=' + fyearId;
  return this.apiService.get(route)
      .pipe(map(
          data => {
              if (data.status === undefined || data.status === null || !data.status) {
                  throw new Error(data.error_description);
              }
              return (data.data);
          }
      ));
}
}
