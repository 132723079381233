import { Injectable } from '@angular/core';
import { ClientSetting } from '../client-setting';

@Injectable({ providedIn: 'root' })
export class ClientSettingService {
    protected appSetting = ClientSetting.settings.app;
    protected administrationModuleSetting = ClientSetting.settings.administration;
    protected businessModuleSetting = ClientSetting.settings.business;
    protected propertyModuleSetting = ClientSetting.settings.property;
    protected accountingModuleSetting = ClientSetting.settings.accounting;
    protected inventoryModuleSetting = ClientSetting.settings.inventory;
    protected salesModuleSetting = ClientSetting.settings.sales;
    protected purchaseModuleSetting = ClientSetting.settings.purchase;
    protected paymentGatewaySetting = ClientSetting.settings.payment_gateway;
    protected serviceSetting = ClientSetting.settings.service;

    /* START APP SETTING SECTION */
    getClientCode(): string {
        return this.appSetting.clientCode;
    }
    getClientState(): number {
        return this.appSetting.clientState;
    }
    getClientFullName(): string {
        return this.appSetting.clientFullName;
    }

    getClientFirstName(): string {
        return this.appSetting.clientFirstName;
    }

    getClientLastName(): string {
        return this.appSetting.clientLastName;
    }

    getClientWebsiteName(): string {
        return this.appSetting.clientWebsiteName;
    }

    getClientLogoPath(): string {
        return this.appSetting.clientLogoPath;
    }

    getclientCurrencyName(): string {
        return this.appSetting.clientCurrencyName;
    }

    getclientCurrencySymbol(): string {
        return this.appSetting.clientCurrencySymbol;
    }
    hasIonicWebsite(): boolean {
        return this.appSetting.hasIonicWebsite;
    }
    getHeadOfficeID(): number {
        return this.appSetting.HeadOfficeID;
    }
    getPageSize(): number {
        return this.appSetting.pageSize;
    }

    getScrollDepth(): number {
        return this.appSetting.scrollDepth;
    }
    getDashboardMessage(): string {
        return this.appSetting.dashboardMessage1 + ' ' + this.appSetting.dashboardMessage2 + ' ' + this.appSetting.dashboardMessage3 ;
    }
    /* END APP SETTING SECTION */

    /* START ADMINISTRATION MODULE SECTION */
    isAdministrationModuleActive(): boolean {
        return this.administrationModuleSetting.isActive;
    }
    /* END ADMINISTRATION MODULE SECTION */

    /* START BUSINESS MODULE SECTION */
    isBusinessModuleActive(): boolean {
        return this.businessModuleSetting.isActive;
    }
    hasUpline(): boolean {
        return this.businessModuleSetting.hasUpline;
    }
    hasLevels(): boolean {
        return this.businessModuleSetting.hasLevels;
    }
    inputLevel(): boolean {
        return this.businessModuleSetting.inputLevel;
    }
    canHaveEqualLevels(): boolean {
        return this.businessModuleSetting.canHaveEqualLevels;
    }
    maximumLevels(): number {
        return this.businessModuleSetting.maximumLevels;
    }
    hasBinarySystem(): boolean {
        return this.businessModuleSetting.hasBinarySystem;
    }

    hasManualPassword(): boolean {
        return this.businessModuleSetting.hasManualPassword;
    }
    isRegistrationWithPin(): boolean {
        return this.businessModuleSetting.isRegistrationWithPin;
    }

    hasAssignPackage(): boolean {
        return this.businessModuleSetting.hasAssignPackage;
    }

    hasMultiplyInPackage(): boolean {
        return this.businessModuleSetting.hasMultiplyInPackage;
    }
    hasMoreThanOnePackage(): boolean {
        return this.businessModuleSetting.hasMoreThanOnePackage;
    }
    hasWallet(): boolean {
        return this.businessModuleSetting.hasWallet;
    }
    walletPointValue(): number {
        return this.businessModuleSetting.walletPointValue;
    }
    hasCashBackROI(): boolean {
        return this.businessModuleSetting.hasCashBackROI;
    }
    hasInstantBenefit(): boolean {
        return this.businessModuleSetting.hasInstantBenefit;
    }
    hasOTPinRegistraion(): boolean {
        return this.businessModuleSetting.hasOTPinRegistraion;
    }
    hideMobileNoInMemberPanel(): boolean {
        return this.businessModuleSetting.hideMobileNoInMemberPanel;
    }
    hideSponsorNameInMemberList(): boolean {
        return this.businessModuleSetting.hideSponsorNameInMemberList;
    }
    hasAssignArea(): boolean {
        return this.businessModuleSetting.hasAssignArea;
    }
    hasTransactionWallet(): boolean {
        return this.businessModuleSetting.hasTransactionWallet;
    }
    hasPurchaseWallet(): boolean {
        return this.businessModuleSetting.hasPurchaseWallet;
    }
    getWalletDeductionPercentage(): number {
        return this.businessModuleSetting.WalletDeductionPercentage;
    }
    getWalletDeductionPercentageNonPAN(): number {
        return this.businessModuleSetting.WalletDeductionPercentageNonPAN;
    }
    transferToTransactionWalletPer(): number {
        return this.businessModuleSetting.transferToTransactionWalletPer;
    }
    transferToTransactionWalletPerNonPAN(): number {
        return this.businessModuleSetting.transferToTransactionWalletPerNonPAN;
    }
    getMinimumWalletWithdrawAmount(): number {
        return this.businessModuleSetting.MinimumWalletWithdrawAmount;
    }
    hasIDTopUp(): boolean {
        return this.businessModuleSetting.hasIDTopUp;
    }
    otpOnWithdraw(): boolean {
        return this.businessModuleSetting.otpOnWithdraw;
    }
    hasIMPSWithdraw(): boolean {
        return this.businessModuleSetting.hasIMPSWithdraw;
    }
    allowMultipleBankAccounts(): boolean {
        return this.businessModuleSetting.allowMultipleBankAccounts;
    }
    hasDesignation(): boolean {
        return this.businessModuleSetting.hasDesignation;
    }

    /* END BUSINESS MODULE SECTION */

    /* START PROPERTY MODULE SECTION */
    isPropertyModuleActive(): boolean {
        return this.propertyModuleSetting.isActive;
    }
    /* END PROPERTY MODULE SECTION */

    /* START ACCOUNTING MODULE SECTION */
    isAccountingModuleActive(): boolean {
        return this.accountingModuleSetting.isActive;
    }
    /* END ACCOUNTING MODULE SECTION */

    /* START INVENTORY MODULE SECTION */
    isInventoryModuleActive(): boolean {
        return this.inventoryModuleSetting.isActive;
    }
    isMLMSaleType(): boolean {
        return this.inventoryModuleSetting.isMLMSaleType;
    }
    MRP_Text(): string {
        return this.inventoryModuleSetting.mrp_text;
    }
    SalePrice_Text(): string {
        return this.inventoryModuleSetting.sale_price_text;
    }
    CostPrice_Text(): string {
        return this.inventoryModuleSetting.cost_price_text;
    }
    /* END INVENTORY MODULE SECTION */

    /* START SALES MODULE SECTION */
    isSalesModuleActive(): boolean {
        return this.salesModuleSetting.isActive;
    }
    /* END SALES MODULE SECTION */

    /* START PURCHASE MODULE SECTION */
    isPurchaseModuleActive(): boolean {
        return this.purchaseModuleSetting.isActive;
    }
    /* END PURCHASE MODULE SECTION */

    /* START PURCHASE MODULE SECTION */
    isPaymentGatewayActive(): boolean {
        return this.paymentGatewaySetting.isActive;
    }

    getOrganizationName(): string {
        return this.paymentGatewaySetting.organization_name;
    }

    getPaymentKey(): string {
        return this.paymentGatewaySetting.payment_key;
    }

    getPaymentImage(): string {
        return this.paymentGatewaySetting.payment_image;
    }

    getPaymentCurrency(): string {
        return this.paymentGatewaySetting.payment_currency;
    }

    getThemeColor(): string {
        return this.paymentGatewaySetting.theme_color;
    }
    hasServive(): boolean {
        return this.serviceSetting.isActive;
    }
    getClientID(): number{
      return this.serviceSetting.clientID;
    }
    /* END PURCHASE MODULE SECTION */
}

