import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterPanchayatMasterArray'
})
export class FilterPanchayatMasterDataPipe implements PipeTransform {
    // transform(items: Array<any>, filter: { [key: string]: any }): Array<any> {
        transform(items: any, filter?: any): any {
            if (!(filter === undefined || filter === null) && filter) {
            return items.filter(currentItem => {
                return (currentItem.PanchayatName.toLowerCase().indexOf(filter.toLowerCase()) > -1);
            });
        } else {
            return items;
        }
    }
}
