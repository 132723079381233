import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserLoginPage } from './pages/ayurveda/user-login/user-login.page';
// debugger;

const routes: Routes = [


  {
    path: 'admin',
    loadChildren: () =>
      import('./pages/main-module/main-module-routing.module').then(
        (m) => m.MainModulePageModule
      ),
  },
  {
    path: 'accounting',
    loadChildren: () =>
      import('./pages/accounting/accounting.module').then(
        (m) => m.AccountingModule
      ),
  },

  {
    path: 'administration',
    loadChildren: () =>
      import('./pages/administration/administration.module').then(
        (m) => m.AdministrationModule
      ),
  },

  {
    path: 'pages',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesPageModule),
  },
  {
    path: 'master',
    loadChildren: () =>
      import('./pages/master/master.module').then((m) => m.MasterModule),
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'home',
    loadChildren: () =>
      import('./pages/ayurveda/adhaar-main.module').then(
        (m) => m.AdhaarMainModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/ayurveda/adhaar-main.module').then(
        (m) => m.AdhaarMainModule
      ),
  },
  { path: '**', redirectTo: 'home' },
  {
    path: 'main-module',
    loadChildren: () =>
      import('./pages/main-module/main-module-routing.module').then(
        (m) => m.MainModulePageModule
      ),
  },

  // { path: '', redirectTo: '/login', pathMatch: 'full' },
  // { path: 'login', component: UserLoginPage },  // <- Removed the extra comma
  // { path: 'detail/:id', component: HeroDetailComponent }
];

// const routes: Routes = [
//   {
//     path: 'login',
//     loadChildren: () =>
//       import('./pages/login/login.module').then((m) => m.LoginPageModule)
//   },

//   {
//     path: 'accounting',
//     loadChildren: () =>
//       import('./pages/accounting/accounting.module').then(
//         (m) => m.AccountingModule
//       )
//   },

//   {
//     path: 'about',
//     loadChildren: () =>
//       import('./pages/about/about.module').then((m) => m.AboutPageModule)
//   },
//   {
//     path: 'support',
//     loadChildren: () =>
//       import('./pages/support/support.module').then((m) => m.SupportPageModule)
//   },
//   {
//     path: 'settings',
//     loadChildren: () =>
//       import('./pages/settings/settings.module').then(
//         (m) => m.SettingsPageModule
//       )
//   },
//   {
//     path: 'messages',
//     loadChildren: () =>
//       import('./pages/messages/messages.module').then(
//         (m) => m.MessagesPageModule
//       )
//   },
//   {
//     path: 'message/:id',
//     loadChildren: () =>
//       import('./pages/message/message.module').then((m) => m.MessagePageModule)
//   },

//   {
//     path: 'search-filter',
//     loadChildren: () =>
//       import('./pages/modal/search-filter/search-filter.module').then(
//         (m) => m.SearchFilterPageModule
//       )
//   },

//   {
//     path: 'master',
//     loadChildren: () =>
//       import('./pages/master/master.module').then((m) => m.MasterModule),
//     runGuardsAndResolvers: 'always'
//   },

//   {
//     path: 'main-module',
//     loadChildren: () =>
//       import('./pages/main-module/main-module-routing.module').then(
//         (m) => m.MainModulePageModule
//       )
//   },

//   {
//     path: 'admin',
//     loadChildren: () =>
//       import('./pages/main-module/main-module-routing.module').then(
//         (m) => m.MainModulePageModule
//       )
//   },
// ,
//   {
//     path: 'admin',
//     loadChildren: () =>
//       import('./pages/main-module/main-module-routing.module').then(
//         (m) => m.MainModulePageModule
//       )
//   },

//   {
//     path: 'administration',
//     loadChildren: () =>
//       import('./pages/administration/administration.module').then(
//         (m) => m.AdministrationModule
//       )
//   },
//   // {
//   //   path: 'ecom',
//   //   loadChildren: () =>
//   //     import('./pages/ayurveda/adhaar-main.module').then(
//   //       (m) => m.AdhaarMainModule
//   //     )
//   // },
//   {
//     path: 'pages',
//     loadChildren: () =>
//       import('./pages/pages.module').then((m) => m.PagesPageModule)
//   },
//   { path: 'home', loadChildren: () => import('./pages/ayurveda/adhaar-main.module').then(m => m.AdhaarMainModule) },
//   { path: '', loadChildren: () => import('./pages/ayurveda/adhaar-main.module').then(m => m.AdhaarMainModule) },
//   { path: '**', redirectTo: 'login' },
// //   {
// //     path: '',
// //     loadChildren: () =>
// //       import('./pages/login/login.module').then((m) => m.LoginPageModule)
// //   },
// //   { path: '**', redirectTo: '/login' }
// ];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  // imports: [
  //   RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  // ],
  // exports: [RouterModule]
})
export class AppRoutingModule {}
