import { Injectable } from '@angular/core';
import { DateFormatEnum } from '../enums/app-enums';
import { DatePipe } from '@angular/common';

@Injectable()
export class DateTimeService {

  constructor(private datepipe: DatePipe) { }

  getDate(): Date {
    return new Date();
  }

  getFormatedDate(dateToFormat: Date, format: DateFormatEnum): string {

    if (format === DateFormatEnum.ddMMyyyy) {
      return this.datepipe.transform(dateToFormat, 'dd-MM-yyyy');
    } else if (format === DateFormatEnum.MMddyyyy) {
      return this.datepipe.transform(dateToFormat, 'MM-dd-yyyy');
    } else if (format === DateFormatEnum.yyyyMMdd) {
      return this.datepipe.transform(dateToFormat, 'yyyy-MM-dd');
    }

  }

}
