import { Pipe, PipeTransform } from '@angular/core';
import { UtilityService } from '../core/services';

@Pipe({
    name: 'filterArray'
})
export class FilterDataPipe implements PipeTransform {

    constructor(private utilityService: UtilityService) { }

    // transform(items: Array<any>, filter: { [key: string]: any }): Array<any> {
    transform(items: any, filter?: any): any {
        if (!this.utilityService.isNullOrUndefined(filter) && filter) {
            return items.filter(currentItem => (currentItem.Text.toLowerCase().indexOf(filter.toLowerCase()) > -1));
        } else {
            return items;
        }
    }
}
