import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IClientSetting } from './models/client-setting-model';

@Injectable()
export class ClientSetting {
    static settings: IClientSetting;
    constructor(private http: HttpClient) { }
    load() {
        const jsonFile = `assets/client/client.${environment.app.client}.json`;
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile).toPromise().then((response: IClientSetting) => {
                ClientSetting.settings = <IClientSetting>response;
                resolve();
            }).catch((response: any) => {
                reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }
}
