import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataService {
    private Member = new BehaviorSubject<any>(null);
    private Members = new BehaviorSubject<any>(null);
    private Pin = new BehaviorSubject<string>(null);

    // for Module detail
    Module$: Observable<any>;
    private Module: Subject<any> = new Subject();

    // for LoginStatus detail
    LoginStatus$: Observable<any>;
    private LoginStatus: Subject<any> = new Subject();

    // for LoginStatus detail
    CartStatus$: Observable<any>;
    private CartStatus: Subject<any> = new Subject();

    private subscriptions: Subscription = new Subscription();

    constructor() {
        this.Module$ = this.Module.asObservable();
        this.LoginStatus$ = this.LoginStatus.asObservable();
        this.CartStatus$ = this.CartStatus.asObservable();
    }


    getUplineMember(): Observable<any> {
        return this.Members.asObservable();
    }

    setPin(pin: string) {
        this.Pin.next(pin);
    }
    getPin(): Observable<string> {
        return this.Pin.asObservable();
    }

    setModule(moduleName: string) {
        this.publish('Module', moduleName);
        window.localStorage['Module'] = moduleName;
    }
    getModule(): string {
        return window.localStorage['Module'];
    }

    publishLoginStatus(status: boolean) {
        this.publish('LoginStatus', status);
    }

    public publish(eventName, eventData?: any) {
        if (eventName === 'Module') {
            this.Module.next(eventData);
        } else if (eventName === 'LoginStatus') {
            this.LoginStatus.next(eventData);
        } else if (eventName === 'CartStatus') {
            this.CartStatus.next(eventData);
        }
    }

    public subscribe(eventName) {
        if (eventName === 'Module') {
            return { subscriptions: this.subscriptions, event: this.Module$ };
        } else if (eventName === 'LoginStatus') {
            return { subscriptions: this.subscriptions, event: this.LoginStatus$ };
        } else if (eventName === 'CartStatus') {
            return { subscriptions: this.subscriptions, event: this.CartStatus$ };
        }
    }
}

