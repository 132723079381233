import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api-service';
import { JwtService } from './jwt-service';
import { Login, ResponseToken } from '../models/app-model';
import { User } from '../models/administration/user-model';
import { map, switchMap } from 'rxjs/operators';
import { UtilityService } from './utility-service';
import { UserAuthoritiesModel, UserModel } from '../models/administration/user-authorities.model';

@Injectable()
export class UserService {

    constructor(
        private apiService: ApiService,
        private jwtService: JwtService,
        private utilityService: UtilityService
    ) { }

        /**
         * Setter & getter for current user
         */
      //   set currentUser(user: UserModel) {
      //     localStorage.setItem(userLocalStorageToken, JSON.stringify(user));
      // }

      // get currentUser(): UserModel {
      //     try {
      //         const lsValue = localStorage.getItem(userLocalStorageToken);
      //         if (!lsValue) {
      //             return undefined;
      //         }

      //         const userData = JSON.parse(lsValue);
      //         return userData;
      //     } catch (error) {
      //         console.error(error);
      //         return undefined;
      //     }
      // }

    setAuth(token: ResponseToken) {
        // Save JWT sent from server in localstorage
        // this.accessToken = response.data;
        this.jwtService.saveToken(token.authToken);
    }

    purgeAuth() {
        // Remove JWT from localstorage
        this.jwtService.destroyToken();
        this.jwtService.destroyUserAuthority();
    }

    logout() {
        this.purgeAuth();
    }

    attemptAuth(credentials: Login): Observable<ResponseToken> {
        const route = '/v1/client/authuser/sign-in';
        return this.apiService.post(route, credentials)
            .pipe(map(
                data => {
                    if (!this.utilityService.isNullOrUndefined(data.status) && data.status) {
                        this.setAuth((data.data));
                    } else {
                        throw new Error(data.error_description);
                    }

                    return data;
                }
            ));
    }

  /**
   * getUserByToken
   *
   */
  getUserByToken(): Observable<any> {
    const accessToken = this.jwtService.getToken();
    if (!this.utilityService.isNullOrUndefined(accessToken) && !this.jwtService.isTokenExpired(accessToken)) {
      const route = `/v1/client/AuthUser/get-user-by-token?token=${accessToken}&apisecret=1234567890`;
      return this.apiService.get(route).pipe(
        switchMap((response) => {
          // Store the current user in the local storage
          const currentUser = response.data;
          // Return a new observable with the response
          return of(currentUser);
        })
      );
    }
    return of(undefined);
  }




    resetPassword(login: Login): Observable<any> {
        const route = '/v1/resetPassword';
        return this.apiService.post(route, login);
    }

    resetMPin(login: Login): Observable<any> {
        const route = '/v1/resetMPin';
        return this.apiService.post(route, login);
    }

    getCurrentUser(): User {
        const data = this.jwtService.geAccessTokenValues();
        const user: User = new User();
        if (!this.utilityService.isNullOrUndefined(data)) {
            user.name = data['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
            user.role = data['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
            user.secret = data.secret;
            user.code = data.code;
            user.branch = data.branch;
            user.mobile = data.phone;
            user.username = data.userName;
            user.profileImage = data.profileImage;
            user.UserType = data.UserType;
            // user.isAutoPassword = data.isAutoPassword.toLowerCase() == 'true' ? true : false;
            user.lastLoginOn = data.lastLoginOn;
        }
        return user;
    }

    // setUserAuthority(userAuthority: UserAuthoritiesModel[]) {
    //     this.jwtService.saveUserAuthority(JSON.stringify(userAuthority));
    // }

    setUserDetails(userAuthority: UserModel[]) {
      this.jwtService.saveUserAuthority(JSON.stringify(userAuthority));
  }

  getUserDetails() {
   return JSON.parse(this.jwtService.getUserAuthority());
}

    getModuleAuthority(module: string): boolean {
        return this.jwtService.getModuleAuthority(module);
    }

    getPageAuthority(page: string): UserAuthoritiesModel {
        return this.jwtService.getPageAuthority(page);
    }
    getPageAuthorityByID(pageID: number): UserAuthoritiesModel {
        return this.jwtService.getPageAuthorityByID(pageID);
    }
}
