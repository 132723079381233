import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { map } from 'rxjs/operators';
import { ApiService } from '../api-service';
import { UserRoleModel } from '../../models/administration/user-role-model';
import { RoleModuleModel } from '../../models/administration/role-module.model';
import { UserAuthoritiesModel } from '../../models/administration/user-authorities.model';

@Injectable()
export class UserRoleService {
    constructor(private apiService: ApiService) { }
    private userRoleBO = new BehaviorSubject<UserRoleModel>(null);

    setUserRoleData(userData: UserRoleModel) {
        this.userRoleBO.next(userData);
    }

    getUserRoleData(): Observable<UserRoleModel> {
        return this.userRoleBO.asObservable();
    }

    createUpdateUserRole(UserRole: UserRoleModel): Observable<any> {
        const route = '/v1/createUserRole';
        return this.apiService.post(route, UserRole)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return data;
                }
            ));
    }

    deleteUserRole(roleID: number): Observable<any> {
        const route = '/v1/deleteUserRole?RoleID=' + roleID;
        return this.apiService.post(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getUserRoleList(): Observable<UserRoleModel[]> {
        const route = '/v1/getUserRoleList';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getRoleModuleList(roleID: number): Observable<RoleModuleModel[]> {
        const route = '/v1/getRoleModuleList?RoleID=' + roleID;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getAllModules(): Observable<RoleModuleModel[]> {
        const route = '/v1/getAllModulesList';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getRoleAuthorities(roleID: number): Observable<UserAuthoritiesModel[]> {
        const route = '/v1/getRoleAuthorities?RoleID=' + roleID;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getAllAuthorities(): Observable<UserAuthoritiesModel[]> {
        const route = '/v1/getAllMainAuthorities';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }
}
