import { Component, OnInit } from '@angular/core';
import { Platform, MenuController, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateProvider } from './providers/translate/translate.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { Pages } from './interfaces/pages';
import { UserService, DataService, UtilityService } from './core/services';
import { User } from './core/models/administration/user-model';
import { ClientSettingService } from './core/services/client-setting-service';
import { UserAuthoritiesModel, UserModel } from './core/models/administration/user-authorities.model';
import { UserInfoService } from './core/services/administration/user-info.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages: Array<Pages> = [];
  public currentUser: UserModel;
  public loginState: boolean;
  apiURL = '';
  blankImageURL = '';
  public modNo: number;
  public isRegistrationWithPin = true;
  private pageAuth: UserAuthoritiesModel;
  constructor(
    private platform: Platform,
    private menu: MenuController,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateProvider,
    private translateService: TranslateService,
    private userService: UserService,
    private userInfoService: UserInfoService,
    private dataService: DataService,
    private clientSettingService: ClientSettingService,
    private utilityService: UtilityService,
    public navCtrl: NavController
  ) {
    // debugger;
    this.isRegistrationWithPin =
      this.clientSettingService.isRegistrationWithPin();

    // Get LoginStatus
    const loginStatus = this.dataService.subscribe('LoginStatus');
    loginStatus.subscriptions.add(
      loginStatus.event.subscribe((userLogin) => {
        if (userLogin) {
          this.currentUser = this.userService.getUserDetails();
          this.apiURL = `${environment.app.api_url}`;
          this.apiURL += '/Assets/Member/';
          this.blankImageURL = this.apiURL+'profileimage.jpg';
          // this.currentUser.role = this.currentUser.role.toLowerCase();
        }
      })
    );
    // document.body.classList.toggle('dark', true);
    // Get LoginStatus
    const module = this.dataService.subscribe('Module');
    module.subscriptions.add(
      module.event.subscribe((moduleName) => {
       if (moduleName === 'administration') {
          // this.initializeAdministrationModuleMenu();
        } else if (moduleName === 'accounting') {
          this.initializeAccountModuleMenu();
        }
      })
    );

    this.initializeApp();
  }

  ngOnInit() {
    this.initializeApp();
    this.currentUser = this.userService.getUserDetails();
    this.initializeAccountModuleMenu();
  }



  initializeAccountModuleMenu() {
    this.appPages = [];
    this.appPages.push({
      title: 'Dashboard',
      url: '/accounting/dashboard',
      direct: 'root',
      icon: 'assets/m-icon/dashboard.png',
    });

      this.appPages.push({
        title: 'Trial Balance',
        url: '/accounting/reports/trial-balance',
        direct: 'root',
        icon: 'assets/m-icon/item.png',
      });
      this.appPages.push({
        title: 'Ledger',
        url: '/accounting/master/ledger-list',
        direct: 'root',
        icon: 'assets/m-icon/wallet.png',
      });
      this.appPages.push({
        title: 'Stock',
        url: '/accounting/master/stock-list',
        direct: 'root',
        icon: 'assets/m-icon/trade.png',
      });
      this.appPages.push({
        title: 'Admin',
        url: '',
        direct: '',
        icon: '',
      });

      this.appPages.push({
        title: 'Company List',
        url: '/administration/company/list',
        direct: 'root',
        icon: 'assets/m-icon/branch.png',
      });

 


  }

  initializeApp() {
    this.platform
      .ready()
      .then(() => {
        this.statusBar.styleDefault();
        setTimeout(() => {
          this.splashScreen.hide();
        }, 1000);
        // this.splashScreen.hide();
        // Set language of the app.
        this.translateService.setDefaultLang(environment.language);
        this.translateService.use(environment.language);
        this.translateService
          .getTranslation(environment.language)
          .subscribe((translations) => {
            this.translate.setTranslations(translations);
          });
      })
      .catch(() => {
        // Set language of the app.
        this.translateService.setDefaultLang(environment.language);
        this.translateService.use(environment.language);
        this.translateService
          .getTranslation(environment.language)
          .subscribe((translations) => {
            this.translate.setTranslations(translations);
          });
      });
  }

  closeMenu() {
    this.menu.close();
    this.userService.purgeAuth();
    if (this.clientSettingService.hasIonicWebsite()) {
      this.navCtrl.navigateRoot('/home/login');
    } else {
      this.navCtrl.navigateRoot('login');
    }
  }

  goToEditProfile() {
    // if (this.currentUser.role.toLowerCase() === 'member') {
    //   this.navCtrl.navigateForward('/member/detail/' + this.currentUser.secret);
    // } else {
    //   this.navCtrl.navigateForward(
    //     '/administration/user/user-detail/' + this.currentUser.secret
    //   );
    // }
  }
}
