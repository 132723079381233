import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loading;

  constructor(public loadingCtrl: LoadingController) { }

  async show() {
    this.loading = await this.loadingCtrl.create({
      duration: 2000
    });
    this.loading.present();
  }
  async showLong() {
    this.loading = await this.loadingCtrl.create({
      duration: 200000
    });
    this.loading.present();
  }

  hide() {
    try {
      this.loading.dismiss();
    } catch (error) { }
  }

  async autoHide(time) {
    this.loading = await this.loadingCtrl.create({
      duration: time
    });
    this.loading.present();
  }
}
