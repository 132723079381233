import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    public navCtrl: NavController
  ) { }

  private formatErrors(error: HttpErrorResponse) {
    // if(error.status == 401){
    //   alert('This version is obsolete, Please contact to system administrator.');
    // }
    const errMessage = (error.error.Message === undefined || error.error.Message === null) ? error.error : error.error.Message;
    return throwError({ message: errMessage });
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.app.api_url}${path}`, { params })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.app.api_url}${path}`,
      JSON.stringify(body)
    ).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.app.api_url}${path}`,
      JSON.stringify(body)
    ).pipe(catchError(this.formatErrors));
  }

  delete(path): Observable<any> {
    return this.http.delete(
      `${environment.app.api_url}${path}`
    ).pipe(catchError(this.formatErrors));
  }

  aeps_post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.app.aeps_url}${path}`,
      JSON.stringify(body)
    ).pipe(catchError(this.formatErrors));
  }

  getBlob(path: string): Observable<any> {
    return this.http.get(`${environment.app.api_url}${path}`, { responseType: 'blob' });
  }
  
}
