import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { map } from 'rxjs/operators';
import { ApiService } from '../api-service';
import { CompanyModel } from '../../models/administration/company.model';


@Injectable()
export class CompanyService {
  constructor(private apiService: ApiService) { }

  getCompanyList(): Observable<CompanyModel[]> {
    const route = '/v1/company/get-all-company';
    return this.apiService.get(route)
        .pipe(map(
            data => {
                if (data.status === undefined || data.status === null || !data.status) {
                    throw new Error(data.error_description);
                }
                return (data.data);
            }
        ));
}

}
