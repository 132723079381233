import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: 'ion-input[appMinValue],[appMinValue][formControlName],[customMin][formControl],[customMin][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: CustomMinDirective, multi: true}]
})
export class CustomMinDirective implements Validator {
  @Input() appMinValue: number;

  validate(c: FormControl): {[key: string]: any} {
      const v = +c.value;
      return ( v < this.appMinValue) ? {appMinValue: true} : null;
  }
}
