import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: 'ion-input[appMaxValue],[appMaxValue][formControlName],[customMax][formControl],[customMax][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: CustomMaxDirective, multi: true}]
})
export class CustomMaxDirective implements Validator {
  @Input() appMaxValue: number;
  validate(c: FormControl): {[key: string]: any} {
      const v = +c.value;
      return ( v > this.appMaxValue) ? {appMaxValue: true} : null;
  }
}
