import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api-service';
import { Login, ResponseToken, Option } from '../models/app-model';
import { map } from 'rxjs/operators';
import { AlertController, LoadingController, Platform, ToastController } from '@ionic/angular';
import { UserAuthorities, ModList } from '../models/authorities-model';

@Injectable()
export class UtilityService {

    constructor(private apiService: ApiService,
        private alertCtrl: AlertController,
        private toastCtrl: ToastController) { }

    async showAlert(text) {
        const alert = await this.alertCtrl.create({
            header: 'Alert',
            message: text,
            buttons: [
                {
                    text: 'Cancel'
                },
                {
                    text: 'Ok'
                }
            ]
        });
        await alert.present();
    }

    async showAlertWithTitle(text, title) {
        const alert = await this.alertCtrl.create({
            header: title,
            message: text,
            buttons: [
                {
                    text: 'Cancel'
                },
                {
                    text: 'Ok'
                }
            ]
        });
        await alert.present();
    }

    async showToast(text: string, position = null) {
        const pos = this.isNullOrUndefined(position) ? 'bottom' : position;

        const toast = await this.toastCtrl.create({
            cssClass: 'bg-profile',
            message: text,
            duration: 3000,
            position: pos
        });
        await toast.present();
    }

    async showToastWithoutBackground(text: string, position = null) {
        const pos = this.isNullOrUndefined(position) ? 'bottom' : position;

        const toast = await this.toastCtrl.create({
            // cssClass: 'bg-profile',
            message: text,
            duration: 3000,
            position: pos
        });
        await toast.present();
    }

    getPlatformInNumber(platform: Platform): number {
        if (platform.is('desktop')) {
            return 3;
        } else if (platform.is('ipad') || platform.is('tablet')) {
            return 4;
        } else {
            return 6;
        }
    }

    addDays(date: Date, days: number): Date {
        date.setDate(date.getDate() + days);
        return date;
    }

    addDaysString(date: Date, days: number): string {
        date.setDate(date.getDate() + days);
        return date.toISOString();
        // return  (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
    }

    addMonths(date: Date, months: number): Date {
        date.setMonth(date.getMonth() + months);
        return date;
    }

    addMonthsString(date: Date, months: number): string {
        date.setMonth(date.getMonth() + months);
        return date.toISOString();
        // return  (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
    }

    round(number, precision) {
        const factor = Math.pow(10, precision);
        const tempNumber = number * factor;
        const roundedTempNumber = Math.round(tempNumber);
        return roundedTempNumber / factor;
    }

    getRandomNumber(digits: number = 4): string {
        let factor = 1;
        for (let index = 0; index < digits - 1; index++) {
            factor = factor * 10;
        }
        return Math.floor((Math.random() * 9 * factor) + factor).toString();
    }

    getSlugFromString(input: string) {
        if (!input) return;

        // make lower case and trim
        var slug = input.toLowerCase().trim();

        // replace invalid chars with spaces
        slug = slug.replace(/[^a-z0-9\s-]/g, ' ');

        // replace multiple spaces or hyphens with a single hyphen
        slug = slug.replace(/[\s-]+/g, '-');

        return slug;
    }

    isNullOrUndefined(value: any) {
        return value === null || value === undefined;
    }

    getAllCountry(): Observable<Option[]> {
        const route = '/v1/getAllCountry';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getAllState(): Observable<Option[]> {
        const route = '/v1/getAllState';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getAllFranchisee(): Observable<Option[]> {
        const route = '/v1/getAllFranchis';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getAllFranchiseeType(): Observable<Option[]> {
        const route = '/v1/getFranchiseeType';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getAllRole(): Observable<Option[]> {
        const route = '/v1/getAllRole';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getAllPackage(): Observable<Option[]> {
        const tPkg = false;
        const route = '/v1/getAlPackage?TopUPPackage=' + tPkg;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }
    // getPropertyPackageList(): Observable<PropertyPackage[]> {
    //     const route = '/v1/getPropertyPackage';
    //     return this.apiService.get(route)
    //         .pipe(map(
    //             data => {
    //                 if (data.Status === undefined || data.Status === null || !data.Status) {
    //                     throw new Error(data.error_description);
    //                 }
    //                 return JSON.parse(data.Data);
    //             }
    //         ));
    // }
    // getServicePackageList(): Observable<PropertyPackage[]> {
    //     const route = '/v1/getServicePackage';
    //     return this.apiService.get(route)
    //         .pipe(map(
    //             data => {
    //                 if (data.Status === undefined || data.Status === null || !data.Status) {
    //                     throw new Error(data.error_description);
    //                 }
    //                 return JSON.parse(data.Data);
    //             }
    //         ));
    // }
    getAllTopUpPackage(): Observable<Option[]> {
        const tPkg = true;
        const route = '/v1/getAlPackage?TopUPPackage=' + tPkg;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getAllLoader(): Observable<Option[]> {
        const route = '/v1/getAllLoader';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getAllCategory(): Observable<Option[]> {
        const route = '/v1/AllCategory';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getAllType(): Observable<Option[]> {
        const route = '/v1/AllType';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getAllUOM(): Observable<Option[]> {
        const route = '/v1/getAllUOM';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getPayMode(): Observable<Option[]> {
        const route = '/v1/getAllPaymode';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getBranch(): Observable<Option[]> {
        const route = '/v1/getBranches';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getFranchiseeType(): Observable<Option[]> {
        const route = '/v1/getFranchiseeType';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getLedger(id: number): Observable<Option[]> {
        const route = '/v1/getledgerBank?id='+id;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getLandGroupDetail(LandGroup: string): Observable<any[]> {
        const route = '/v1/getLandGroupDetail?LandGroupCode=' + LandGroup;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getPin(PinNo: string, Secret: string): Observable<any[]> {
        const route = '/v1/getPin?PinNo=' + PinNo + '&Secret=' + Secret;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    checkProperty(PropertyCode: string): Observable<any[]> {
        const route = '/v1/checkProperty?PropertyCode=' + PropertyCode;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    checkPropertyPlotting(PropertyCode: string): Observable<any[]> {
        const route = '/v1/checkPropertyPlotting?PropertyCode=' + PropertyCode;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getTotalPlots(ID: number): Observable<any[]> {
        const route = '/v1/getTotalPlots?ID=' + ID;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getPlotDetail(Plot: string, PlotPlanNo: string): Observable<any[]> {
        const route = '/v1/getPlotDetail?Plot=' + Plot + '&PlotPlanNo=' + PlotPlanNo;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getBookingDetail(BookingID: number): Observable<any[]> {
        const route = '/v1/getBookingDetail?ID=' + BookingID;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getEmiDetail(BookingCode: string, ReceptDt: string): Observable<any[]> {
        const route = '/v1/getEmiDetail?BookingCode=' + BookingCode + '&ReceptDt=' + ReceptDt;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getEmiDetailByInstNo(BookingCode: string, ReceptDt: string, InstNoTo: number): Observable<any[]> {
        const route = '/v1/getEmiDetailByInstNo?BookingCode=' + BookingCode + '&ReceptDt=' + ReceptDt + '&InstNoTo=' + InstNoTo;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getInstDetail(BookingID: number): Observable<any[]> {
        const route = '/v1/getInstDetail?ID=' + BookingID;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getmemberName(MemberCode: string): Observable<any[]> {
        const route = '/v1/getMemberName?MemberCode=' + MemberCode;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getFranchiseeDetail(Franchisee: number): Observable<any[]> {
        const route = '/v1/getFranchisee?ID=' + Franchisee;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getPackageAmount(Val: number): Observable<any[]> {
        const route = '/v1/getPackageDetail?ID=' + Val;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getWalletAmount(Code: string): Observable<any[]> {
        const route = '/v1/getWalletAmount?Code=' + Code;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getTotalPin(): Observable<any[]> {
        const route = '/v1/getTotalPin';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getModules(): Observable<Option[]> {
        const route = '/v1/getAllModules';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getAuthorities(auths: UserAuthorities): Observable<Option[]> {
        const route = '/v1/getAllAuthorities';
        return this.apiService.post(route, auths)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));

    }

    getMiscAuthorities(auths: UserAuthorities): Observable<Option[]> {
        const route = '/v1/getAllMiscAuthorities';
        return this.apiService.post(route, auths)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));

    }

    getAllCategories(): Observable<Option[]> {
        const route = '/v1/getAllCategories';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getGroupsByCategoryId(categoryId: number): Observable<Option[]> {
        const route = '/v1/getAllGroupSubGroup?Query=GetGroup&ID=' + categoryId;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getSubGroupsByGroupId(groupId: number): Observable<Option[]> {
        const route = '/v1/getAllGroupSubGroup?Query=GetSubGroup&ID=' + groupId;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }
    getAllItem(): Observable<Option[]> {
        const route = '/v1/getItems';
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }
    getAllVarient(id:number): Observable<Option[]> {
        const route = '/v1/getAllItemVarient?id='+id;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (this.isNullOrUndefined(data.Status) || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }
}
