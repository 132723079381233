import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { JwtService } from './jwt-service';
import { NavController } from '@ionic/angular';

@Injectable()
export class AuthGuard  {
  constructor(
    public navCtrl: NavController,
    private jwtService: JwtService
  ) { }

  canActivate(): boolean {
    if (!this.jwtService.isTokenExpired()) {
      return true;
    }
    this.navCtrl.navigateForward('/login');
    return false;
  }
}
