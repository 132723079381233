import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '../api-service';

import { map } from 'rxjs/operators';

import { MasterInfoModel, MasterTypeModel } from '../../models/shared/master-info.model';
import { PersonalInfoModel } from '../../models/shared/personal-info.model';

@Injectable()
export class MasterInfoService {
    constructor(private apiService: ApiService) { }
    // private masterInformation = new BehaviorSubject<MasterInfoModel>(null);
    private masterInformation = new BehaviorSubject<MasterInfoModel>(null);
    private personalformation = new BehaviorSubject<PersonalInfoModel>(null);
    private masterParams = new BehaviorSubject<MasterTypeModel>(null);

    setMasterInfoData(mastInfo: MasterInfoModel) {
        // this.masterInformation.next(JSON.stringify(mastInfo));
        this.personalformation.next(mastInfo);
    }

    getMasterInfoData(): Observable<MasterInfoModel> {
        // return JSON.parse(this.masterInformation);
        return this.masterInformation.asObservable();
    }

    setAddressData(prInfo: PersonalInfoModel) {
        this.personalformation.next(prInfo);
    }

    getAddressData(): Observable<PersonalInfoModel> {
        return this.personalformation.asObservable();
    }

    setMasterParameters(maastTypeObj: MasterTypeModel) {
        this.masterParams.next(maastTypeObj);
    }

    getMasterParameters(): Observable<MasterTypeModel> {
        return this.masterParams.asObservable();
    }

    createUpdateMasterInfo(masterInfoData: MasterInfoModel): Observable<any> {
        const route = '/v1/createUpdateMasterInfo';
        return this.apiService.post(route, masterInfoData)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return data;
                }
            ));
    }

    getAllMasterInfoList(masterTypeID: number, pageNo: number, pageSize: number, SearchKey: string):
        Observable<MasterInfoModel[]> {
        const route = '/v1/getAllMasterInfoList?pageNo=' + pageNo + '&MasterTypeID=' + masterTypeID + '&pageSize=' +
            pageSize + '&SearchKey=' + SearchKey;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getMasterTypeDetails(masterTypeID: number): Observable<MasterTypeModel> {
        const route = '/v1/GetMasterTypeDetails?MasterTypeID=' + masterTypeID;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getMasterInfoByID(masterInfoID: number, masterTypeID: number): Observable<MasterInfoModel> {
        const route = '/v1/GetMasterInfoByID?MasterInfoID=' + masterInfoID + '&MasterTypeID=' + masterTypeID;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }
    getMasterInfoByCode(Code: string, masterTypeID: number): Observable<MasterInfoModel> {
        const route = '/v1/GetMasterInfoByCode?Code=' + Code + '&MasterTypeID=' + masterTypeID;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }
    getMasterInfoBySecret(Secret: string, masterTypeID: number): Observable<MasterInfoModel> {
        const route = '/v1/GetMasterInfoBySecret?Secret=' + Secret + '&MasterTypeID=' + masterTypeID;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    deleteMasterInfo(masterInfoID: number, Code: string): Observable<any[]> {
        const route = '/v1/DeleteMasterInfo?MasterInfoID=' + masterInfoID + '&Code=' + Code;
        return this.apiService.post(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    updatePassword(masterInfoData: MasterInfoModel): Observable<any> {
        const route = '/v1/UpdateMasterInfoPassword';
        return this.apiService.post(route, masterInfoData)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return data;
                }
            ));
    }

    uploadProfileImages(masterInfo: MasterInfoModel): Observable<any[]> {
        const route = '/v1/UploadProfileImage';
        return this.apiService.post(route, masterInfo)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    uploadKycIDImages(masterInfo: MasterInfoModel): Observable<any[]> {
        const route = '/v1/UploadKycIDImage';
        return this.apiService.post(route, masterInfo)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    uploadKycPANImages(masterInfo: MasterInfoModel): Observable<any[]> {
        const route = '/v1/UploadKycPANImage';
        return this.apiService.post(route, masterInfo)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    updateKYC(masterInfoData: MasterInfoModel): Observable<any> {
        const route = '/v1/UpdateMasterKYC';
        return this.apiService.post(route, masterInfoData)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return data;
                }
            ));
    }

    addUpdateAddress(masterInfoData: MasterInfoModel): Observable<any> {
        const route = '/v1/AddUpdateAddress';
        return this.apiService.post(route, masterInfoData)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return data;
                }
            ));
    }

    setDefaultAddress(masterInfoID: number, addressID: number, addrType: number): Observable<any> {
        const route = '/v1/SetDefaultAddress?MasterInfoID=' + masterInfoID + '&AddressID=' + addressID + '&AddressTypeID=' + addrType;
        return this.apiService.post(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return data;
                }
            ));
    }

    removeAddress(addressID: number): Observable<any> {
        const route = '/v1/RemoveAddress?AddressID=' + addressID;
        return this.apiService.post(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return data;
                }
            ));
    }

    getAddressList(masterInfoID: number): Observable<PersonalInfoModel[]> {
        const route = '/v1/GetAddressList?MasterInfoID=' + masterInfoID;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getAddressByID(addressID: number): Observable<PersonalInfoModel> {
        const route = '/v1/GetAddressByID?AddressID=' + addressID;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    blockMasterInfo(masterInfoID: number, Code: string): Observable<any> {
        const route = '/v1/BlockMasterInfo?MasterInfoID=' + masterInfoID + '&Code=' + Code;
        return this.apiService.post(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return data;
                }
            ));
    }

    unBlockMasterInfo(masterInfoID: number, Code: string): Observable<any> {
        const route = '/v1/UnBlockMasterInfo?MasterInfoID=' + masterInfoID + '&Code=' + Code;
        return this.apiService.post(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return data;
                }
            ));
    }

    createUpdateMasterBankInfo(masterInfoData: MasterInfoModel): Observable<any> {
        const route = '/v1/CreateMasterBankDetails';
        return this.apiService.post(route, masterInfoData)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return data;
                }
            ));
    }

    deleteMasterBankInfo(masterInfoBankID: number): Observable<any[]> {
        const route = '/v1/DeleteMasterBankInfo?MasterBankInfoID=' + masterInfoBankID;
        return this.apiService.post(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getMasterBankList(masterInfoID: number): Observable<MasterInfoModel[]> {
        const route = '/v1/GetMasterBankList?MasterInfoID=' + masterInfoID;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }

    getMasterBankByID(bankID: number): Observable<MasterInfoModel> {
        const route = '/v1/GetMasterBankDetailsByID?BankID=' + bankID;
        return this.apiService.get(route)
            .pipe(map(
                data => {
                    if (data.Status === undefined || data.Status === null || !data.Status) {
                        throw new Error(data.error_description);
                    }
                    return JSON.parse(data.Data);
                }
            ));
    }
}
