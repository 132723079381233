export enum DateFormatEnum {
    ddMMyyyy,
    MMddyyyy,
    yyyyMMdd
}

export enum ConnectionStatusEnum {
    Online,
    Offline
}

export enum instrumentModeType {
    Cash = 1,
    Bank = 2,
    Wallet = 3,
    FOC = 4
}
export enum enmApprovalStatus {
    Pending = 0,
    Approved = 1,
    Rejected = 2
}

export enum enmGroupType {
    Assets = 1,
    Liabilities = 2,
    Incomes = 3,
    Expenses = 4
}

export enum enmLedgerType {
    Other = 1,
    Member = 2,
    Customer = 3,
    Investor = 4,
    Owner = 4
}

export enum enmLedgerCategory {
    PersonalAccount = 1,
    RealAccount = 2,
    NominalAccount = 3,
}

export enum enmVoucherType {
    Receipt = 1,
    Payment = 2,
    Contra = 3,
    Journal = 4
}
