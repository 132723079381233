export class User {
  secret: string;
  name: string;
  code: string;
  role: string;
  designation: string;
  username: string;
  email: string;
  profileImage: string;
  mobile: string;
  branch: string;
  isAutoPassword: boolean;
  lastLoginOn: Date;
  UserType: string;
}
