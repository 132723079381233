import { NgModule } from '@angular/core';

import { TermSearchPipe } from './term-search.pipe';
import { NumberToWordsPipe } from './number-to-words.pipe';
import { FilterDataPipe } from './filter-data.pipe';
import { FilterGroupDataPipe } from './filter-group.pipe';
import { FilterFLNameDataPipe } from './filter-flname.pipe';
import { FilterAuthorityDataPipe } from './filter-authority.pipe';
import { FilterFranchiseTypeDataPipe } from './filter-franchise-type.pipe';
import { FilterStateMasterDataPipe } from './filter-state-master.pipe';
import { FilterDistrictMasterDataPipe } from './filter-district-master.pipe';
import { FilterSubDistrictMasterDataPipe } from './filter-sub-district-master.pipe';
import { FilterPanchayatMasterDataPipe } from './filter-panchayat-master.pipe';
import { RoundPipe } from './math-round';

export const pipes = [
    TermSearchPipe,
    NumberToWordsPipe,
    FilterDataPipe,
    FilterGroupDataPipe,
    FilterFLNameDataPipe,
    FilterAuthorityDataPipe,
    FilterFranchiseTypeDataPipe,
    FilterStateMasterDataPipe,
    FilterDistrictMasterDataPipe,
    FilterSubDistrictMasterDataPipe,
    FilterPanchayatMasterDataPipe,
    RoundPipe
];

@NgModule({
  declarations: [pipes],
  exports: [pipes]
})

export class PipesModule { }
