import { Injectable } from '@angular/core';
import { DateFormatEnum } from '../enums/app-enums';
import { DatePipe } from '@angular/common';

@Injectable()
export class ImageService {

  constructor() { }


}
