// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-list.list-md {
  padding: 0;
}

.menu-icon {
  width: 20px;
  margin-right: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAOQ;EACI,UAAA;AANZ;;AAmBA;EAAW,WAAA;EAAY,kBAAA;AAdvB","sourcesContent":[":host {\r\n    // ion-content {\r\n    //     // --background: linear-gradient(135deg, var(--ion-color-dark), var(--ion-color-primary));\r\n    //     --background:  #1ca5ba //linear-gradient(135deg, #1ca5ba,  #1ca5ba);\r\n    // }\r\n\r\n    ion-list {\r\n        &.list-md {\r\n            padding: 0;\r\n        }\r\n    }\r\n    // ion-menu {\r\n    //     --max-width:350px!important;\r\n    // }\r\n    // ion-split-pane {\r\n    //     --side-max-width:350px;\r\n    //}\r\n    // .split-pane-visible > .split-pane-side {\r\n    //     max-width: 25%;\r\n    //   }\r\n}\r\n.menu-icon{width:20px; margin-right:30px;}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
