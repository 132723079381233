import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors/http-token-interceptor';
import { DatePipe } from '@angular/common';

import {
  ApiService,
  AuthGuard,
  JwtService,
  UserService,
  DateTimeService,
  UtilityService,

  ImageService,
  LoadingService
} from './services';

import {
  CustomMaxDirective,
  CustomMinDirective,
  NumberDirective
} from './directives';

import { from } from 'rxjs';
import { LedgerMasterService } from './services/accounting/ledger-master.service';
import { UserAuthorityService } from './services/authority-service';

import { UserInfoService } from './services/administration/user-info.service';
import { UserRoleService } from './services/administration/user-role.service';
import { CompanyService } from './services/administration/company.service';
import { MasterInfoService } from './services/shared/master-info.service';
import { SMSService } from './services/SMS/sms.service';



@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    ApiService,
    AuthGuard,
    JwtService,
    UserService,
    CompanyService,
    DateTimeService,
    UtilityService,
    DatePipe,
    ImageService,


    LoadingService,
    // This franchise service will be removed
    LedgerMasterService,
    UserAuthorityService,
    UserInfoService,
    UserRoleService,
    MasterInfoService,
    SMSService
  ],
  declarations: [
    NumberDirective,
    CustomMinDirective,
    CustomMaxDirective
  ],
  exports: [
    NumberDirective,
    CustomMinDirective,
    CustomMaxDirective
  ]
})
export class CoreModule { }
