import { Pipe, PipeTransform } from '@angular/core';
import { UserAuthoritiesModel } from '../core/models/administration/user-authorities.model';

@Pipe({
    name: 'filterAuthorities'
})
export class FilterAuthorityDataPipe implements PipeTransform {
    // transform(items: Array<UserAuthoritiesModel>, filter: { [key: string]: any }): Array<UserAuthoritiesModel> {
    transform(items: any, filter?: any): any {
        if (!(filter === null || filter === undefined) && filter) {
            return items.filter(currentItem => ((currentItem.ModuleName)
                .toLowerCase().indexOf(filter.toLowerCase()) > -1));
        } else {
            return items;
        }
    }
}
